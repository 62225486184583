<template>
  <div id="admin-item">
    <admin-template>
      <template slot="admin-content">
        <admin-navbar
          :title="title"
          :linksArray="linksArray"
          :returnUrl="returnUrl"
        ></admin-navbar>

        <b-container>
          <b-row align-h="center">
            <b-col></b-col>
            <b-col md="5">
              <validation-observer ref="observer" v-slot="{ handleSubmit }">
                <b-form @submit.prevent="handleSubmit(onSubmit)">
                  <validation-provider
                    name="分类"
                    :rules="{ required: true, min: 4 }"
                    v-slot="{ errors }"
                  >
                    <label class="sr-only" for="inline-form-input-item"
                      >新分类名称</label
                    >
                    <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                      <b-input
                        v-model="item"
                        id="inline-form-input-item"
                        placeholder="输入新分类名称"
                        autofocus
                      ></b-input>
                    </b-input-group>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </validation-provider>
                  <b-button type="submit" variant="primary" class="my-3" style="width: 100%"
                    >添加新分类</b-button
                  >
                </b-form>
              </validation-observer>
            </b-col>
            <b-col></b-col>
          </b-row>
        </b-container>
        
      </template>
    </admin-template>
  </div>
</template>

<script>
import AdminTemplate from "../components/AdminTemplate";
import AdminNavbar from "../components/AdminNavbar";

export default {
  name: "ItemAdd",
  data() {
    return {
      title: "添加网站类型",
      linksArray: [
        {
          title: "网站类型",
          url: "/item",
        },
      ],
      returnUrl: "/item",
      item: null,
    };
  },
  components: {
    AdminTemplate,
    AdminNavbar,
  },
  methods: {
    onSubmit() {
      
      let username = window.sessionStorage.getItem("username");
      let token = window.sessionStorage.getItem("token");

      this.$axios
        .post("/api/v20/site/item/add/" + username + "/" + token, {
          item: this.item,
        })
        .then((response) => {
          let data = response.data;

          switch (data.code) {
            case 0:
              break;
            
            case 1:
              this.$router.push('/item')
              break;

            default:
              this.$bvModal.msgBoxOk(data.message);
              break;
          }
        })
        .catch((error) => {
          // console.log(error);
          
        });
    },
    getValidationState(errors) {},
  },
};
</script>