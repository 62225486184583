<template>
  <div id="page-admin">
    <page-header></page-header>
    <b-container fluid class="content">
      <slot name="admin-content"> </slot>
    </b-container>
    <page-footer></page-footer>
  </div>
</template>

<script>
import PageHeader from "../components/PageHeader";
import PageFooter from "../components/PageFooter";
import AdminNavbar from "../components/AdminNavbar";

export default {
  name: "AdminTemplate",
  components: {
    PageHeader,
    PageFooter,
    AdminNavbar,
  },
};
</script>

<style scoped>
.content {
  min-height: 450px;
}
</style>