<template>
  <div id="admin-url">
    <admin-template>
      <template slot="admin-content">
        <admin-navbar
          title="我的信息"
          :linksArray="linksArray"
          :returnUrl="returnUrl"
        ></admin-navbar>
        <!-- 列表 -->
        <b-container>
          <b-row>
            <b-col md="6" offset-md="3">
              <b-card
                :title="nickname"
                :img-src="homeImageUrl"
                img-alt="Image"
                img-top
                tag="article"
                class="mb-2"
                align="center"
              >
                <b-card-text>
                  {{ username }}
                </b-card-text>

                <!-- <b-button href="#" variant="primary">Go somewhere</b-button> -->
              </b-card>
            </b-col>
          </b-row>
        </b-container>
      </template>
    </admin-template>
  </div>
</template>

<script>
import AdminTemplate from "../components/AdminTemplate";
import AdminNavbar from "../components/AdminNavbar";
import homeImageUrl from "../assets/home.jpg"

export default {
  name: "Mine",
  data() {
    return {
      homeImageUrl: homeImageUrl,
      title: "我的信息",
      linksArray: [],
      returnUrl: "/",
      username: null,
      nickname: null,
      token: null,
    };
  },
  components: {
    AdminTemplate,
    AdminNavbar,
  },
  created: function () {
    this.username = sessionStorage.getItem("username");
    this.nickname = sessionStorage.getItem("nickname");
    this.token = sessionStorage.getItem("token");
  },
  methods: {},
};
</script>