<template>
  <div id="admin-signature">
    <admin-template>
      <template slot="admin-content">
        <admin-navbar
          :title="title"
          :linksArray="linksArray"
          :returnUrl="returnUrl"
        ></admin-navbar>
        <b-container fluid="sm">
          <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.prevent="handleSubmit(onSubmit)">
              <validation-provider
                name="签名内容"
                :rules="{ required: true, min: 2 }"
                v-slot="{ errors }"
              >
                <b-row class="mb-2">
                  <b-col md="3">
                    <label>签名内容</label>
                  </b-col>
                  <b-col md="9">
                    <b-input
                      v-model="signature"
                      id="inline-form-input-name"
                      class="mb-2 mr-sm-2 mb-sm-0"
                      placeholder="输入签名"
                    ></b-input>
                  </b-col>
                </b-row>
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
              <b-button type="submit" variant="primary">修改签名</b-button>
            </b-form>
          </validation-observer>
        </b-container>
      </template>
    </admin-template>
  </div>
</template>

<script>
import AdminTemplate from "../components/AdminTemplate";
import AdminNavbar from "../components/AdminNavbar";

export default {
  name: "SignatureAdd",
  data() {
    return {
      title: "签名修改",
      linksArray: [
        {
          title: "签名管理",
          url: "/signature",
        },
      ],
      returnUrl: "/signature",
      usename: null,
      token: null,
      id: null,
      signature: "",
    };
  },
  components: {
    AdminTemplate,
    AdminNavbar,
  },
  created() {
    this.username = window.sessionStorage.getItem("username");
    this.token = window.sessionStorage.getItem("token");

    let row = JSON.parse(this.$route.query.row);
    this.id = row.id;
    this.signature = row.signature;
  },
  methods: {
    onSubmit() {
      this.$axios
        .post(
          "/api/v20/signature/edit/save/" + this.username + "/" + this.token,
          {
            id: this.id,
            signature: this.signature,
          }
        )
        .then((response) => {
          let data = response.data;
          // console.log(response);

          switch (data.code) {
            case 0:
              this.$router.push("/login");
              break;
            case 1:
              this.$router.push("/signature");
              break;
            default:
              this.$bvModal.msgBoxOk(data.message);
              break;
          }
        })
        .catch((error) => {
          // this.$message.error(error);
        });
    },
    onReset() {},
  },
};
</script>