<template>
  <div id="home-content" class="bg-dark">
    <b-container class="bg-dark mb-2" fluid>
      <b-row>
        <!-- 网址分类导航列表 -->
        <b-col md="4">
          <b-card bg-variant="secondary" class="text-center my-3">
            <b-card-text>
              <b-nav class="m-2">
                <b-nav-item
                  href="#"
                  @click="toItemBox(row.itemid)"
                  v-for="row in itemData"
                  :key="row.id"
                  link-classes="text-light"
                  >{{ row.item }}</b-nav-item
                >
              </b-nav>
            </b-card-text>
          </b-card>
        </b-col>
        <!-- 推荐网站列表 -->
        <b-col md="8">
          <b-nav class="m-2">
            <b-nav-item
              :href="subitem.url"
              v-for="subitem in hotSiteArray"
              :key="subitem.id"
              link-classes="text-light"
              target="_blank"
              >{{ subitem.site }}</b-nav-item
            >
          </b-nav>
        </b-col>
      </b-row>
    </b-container>

    <b-container fluid>
      <b-row class="text-center">
        <!-- 导航网站分类列表 -->
        <b-col md="8" class="">
          
          <b-card-group columns>
            <b-card
              bg-variant="secondary"
              text-variant="white"
              class="mb-2"
              v-for="item in listsArray"
              :key="item.itemid"
              :id="item.itemid"
            >
              <b-card-title>
                <b-row align-h="start">
                  <b-col md="8" class="text-left">
                    <b-link @click="toSite(item)" class="mr-3"
                      ><b-icon icon="folder" variant="info"></b-icon
                    ></b-link>
                    {{ item.item }}
                  </b-col>
                  <b-col md="2" offset-md="2">
                    <b-link @click="toSiteAdd(item.itemid, item.item)"
                      ><b-icon icon="plus" variant="light"></b-icon
                    ></b-link>
                  </b-col>
                </b-row>
              </b-card-title>
              <b-card-text>
                <b-nav>
                  <b-nav-item
                    :href="subitem.url"
                    v-for="subitem in item.siteArray"
                    :key="subitem.id"
                    link-classes="text-light"
                    target="_blank"
                    >{{ subitem.site }}</b-nav-item
                  >
                </b-nav>
              </b-card-text>
            </b-card>
          </b-card-group>
        </b-col>

        <b-col md="4">
          <!-- 事务提醒列表 -->
          <b-card bg-variant="secondary" text-variant="white" class="mb-2">
            <b-card-title>
              <b-row>
                <b-col md="6" class="text-left">
                  <b-link href="/note" class="mr-3"
                    ><b-icon icon="book" variant="info"></b-icon
                  ></b-link>
                  事务提醒
                </b-col>

                <b-col md="3" offset-md="3">
                  <b-link href="/note/add"
                    ><b-icon icon="plus" variant="light"></b-icon
                  ></b-link>
                </b-col>
              </b-row>
            </b-card-title>

            <b-card-text>
              <b-alert
                show
                variant="danger"
                class="text-left"
                v-for="row in noteMarkData"
                :key="row.id"
              >
                {{ row.date_note }} {{ row.note }}</b-alert
              >
              <b-alert
                show
                variant="light"
                class="text-left"
                v-for="row in noteData"
                :key="row.id"
              >
                {{ row.date_note }} {{ row.note }}</b-alert
              >
            </b-card-text>
          </b-card>

          <!-- 网址搜索 -->
          <b-card bg-variant="secondary" text-variant="white" class="mb-2">
            <b-input-group>
              <b-form-input
                v-model="searchKeywds"
                placeholder="输入查询关键词"
              ></b-form-input>
              <b-input-group-append>
                <b-button variant="info" @click="toUrlSearch"
                  >收藏搜索</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-card>
          <!-- 热点网址列表 -->
          <b-card bg-variant="secondary" text-variant="white" class="mb-2">
            <b-card-title>
              <b-row>
                <b-col md="6" class="text-left">
                  <b-link href="/url/hot" class="mr-3"
                    ><b-icon icon="book" variant="info"></b-icon
                  ></b-link>
                  热点收藏
                </b-col>

                <b-col md="3" offset-md="3">
                  <b-link href="/url/hot"
                    ><b-icon icon="plus" variant="light"></b-icon
                  ></b-link>
                </b-col>
              </b-row>
            </b-card-title>

            <b-card-text>
              <b-nav vertical class="text-left">
                <b-nav-item
                  v-for="row in urlHotData"
                  :key="row.id"
                  @click="toUrl(row.url)"
                  link-classes="text-light"
                  >{{ row.title }}
                </b-nav-item>
              </b-nav>
            </b-card-text>
          </b-card>

          <!-- 网址收藏列表 -->
          <b-card bg-variant="secondary" text-variant="white" class="mb-2">
            <b-card-title>
              <b-row>
                <b-col md="6" class="text-left">
                  <b-link href="/url/current" class="mr-3"
                    ><b-icon icon="book" variant="info"></b-icon
                  ></b-link>
                  网页收藏
                </b-col>

                <b-col md="3" offset-md="3">
                  <b-link href="/url/add"
                    ><b-icon icon="plus" variant="light"></b-icon
                  ></b-link>
                </b-col>
              </b-row>
            </b-card-title>

            <b-card-text>
              <b-nav vertical class="text-left">
                <b-nav-item
                  v-for="row in urlData"
                  :key="row.id"
                  @click="toUrl(row.url)"
                  link-classes="text-light"
                  >{{ row.title }}
                  <b-icon
                    class="text-warning"
                    v-if="row.is_hot == 1"
                    icon="heart-fill"
                  ></b-icon
                ></b-nav-item>
              </b-nav>
            </b-card-text>
          </b-card>

          <!-- 网页关键词列表 -->
          <b-card bg-variant="secondary" text-variant="white" class="mb-2">
            <b-card-title>
              <b-row>
                <b-col md="6" class="text-left">
                  <b-link href="/url/keywds" class="mr-3"
                    ><b-icon icon="book" variant="info"></b-icon
                  ></b-link>
                  关键词
                </b-col>

                <b-col md="3" offset-md="3">
                  <b-link @click="keywdsCount"
                    ><b-icon icon="arrow-repeat" variant="light"></b-icon
                  ></b-link>
                </b-col>
              </b-row>
            </b-card-title>

            <b-card-text>
              <b-nav class="m-2">
                <b-nav-item
                  v-for="row in keywdsData"
                  :key="row.id"
                  link-classes="text-light"
                  @click="toKeywdsLists(row.keywds)"
                  >{{ row.keywds }}</b-nav-item
                >
              </b-nav>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "HomeContent",
  data() {
    return {
      userInfo: "",
      username: "",
      token: "",
      itemData: [],
      listsArray: [],
      hotSiteArray: [],
      noteMarkData: [],
      noteData: [],
      urlData: [],
      urlHotData: [],
      keywdsData: [],
      searchKeywds: null,
    };
  },
  methods: {
    toItemBox: function (itemid) {
      let element = document.getElementById(itemid);
      // let scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop
      // console.log(element)
      element.scrollIntoView();
      // window.scrollTo(0,100)
      // element.scrollTop(0,100)
      // this.$router.push("/index/#" + itemid);
    },
    toSite: function (row) {
      this.$router.push("/site/" + row.itemid + "/" + row.item);
    },
    toSiteAdd: function (itemid, item) {
      this.$router.push("/site/add?itemid=" + itemid + "&item=" + item);
    },
    toUrl(url) {
      window.open(url, "_blank");
    },
    keywdsCount() {
      this.$router.push("/url/keywds/count");
    },
    toKeywdsLists(keywds) {
      this.$router.push("/url/keywds/lists?keywds=" + keywds);
    },
    toUrlSearch() {
      if (this.searchKeywds != null) {
        this.$router.push("/url/search?keywds=" + this.searchKeywds);
      }
    },
  },
  created: function () {
    this.username = window.sessionStorage.getItem("username");
    this.token = window.sessionStorage.getItem("token");

    // 获取网址分类数据
    this.$axios
      .get("/api/v20/home/item/" + this.username + "/" + this.token, {
        isMark: 1,
      })
      .then((response) => {
        let data = response.data;
        // console.log(response)
        switch (data.code) {
          case 0:
            this.$router.push("/login");
            break;
          case 1:
            this.itemData = data.data;
            // console.log(JSON.stringify(retData));
            // this.$message.success(data.message);
            break;
          default:
            retData = null;
            // this.$message.error(data.message);
            break;
        }
      })
      .catch((error) => {
        // console.log(error);
        // this.$message.error(error);
      });

    //常用网站数据
    this.$axios
      .get("/api/v20/home/hotsite/" + this.username + "/" + this.token)
      .then((response) => {
        let data = response.data;

        switch (data.code) {
          case 0:
            this.$router.push("/login");
            break;
          case 1:
            this.hotSiteArray = data.data;
            // console.log(JSON.stringify(listsArray));
            // this.$message.success(data.message);
            break;

          default:
            // this.$message.error(data.message);
            break;
        }
      })
      .catch((error) => {
        // console.log(error);
        // this.$message.error(data.message);
      });

    //网站分类列表数据
    this.$axios
      .get("/api/v20/home/site/" + this.username + "/" + this.token)
      .then((response) => {
        // console.log(response);
        let data = response.data;

        switch (data.code) {
          case 0:
            this.$router.push("/login");
            break;
          case 1:
            this.listsArray = data.data;
            // console.log(JSON.stringify(listsArray));
            // this.$message.success(data.message);
            break;

          default:
            // this.$message.error(data.message);
            break;
        }
      })
      .catch((error) => {
        // console.log(error);
        // this.$message.error(data.message);
      });

    // 重点提醒数据
    this.$axios
      .post("/api/v20/home/note/" + this.username + "/" + this.token, {
        isMark: 1,
      })
      .then((response) => {
        let data = response.data;
        // console.log(response)
        switch (data.code) {
          case 0:
            this.$router.push("/login");
            break;
          case 1:
            this.noteMarkData = data.data;
            // console.log(JSON.stringify(retData));
            // this.$message.success(data.message);
            break;
          default:
            retData = null;
            // this.$message.error(data.message);
            break;
        }
      })
      .catch((error) => {
        // console.log(error);
        this.$message.error(error);
      });

    // 普通提醒数据
    this.$axios
      .post("/api/v20/home/note/" + this.username + "/" + this.token, {
        isMark: 0,
      })
      .then((response) => {
        let data = response.data;
        // console.log(response)
        switch (data.code) {
          case 0:
            this.$router.push("/login");
            break;

          case 1:
            this.noteData = data.data;
            // console.log(JSON.stringify(retData));
            // this.$message.success(data.message);
            break;

          default:
            retData = null;
            // this.$message.error(data.message);
            break;
        }
      })
      .catch((error) => {
        // console.log(error);
        // this.$message.error(error);
      });

    // 热点网址收藏数据
    this.$axios
      .post("/api/v20/home/url/hot/" + this.username + "/" + this.token, {
        limit: 5,
      })
      .then((response) => {
        let data = response.data;
        // console.log(response)
        switch (data.code) {
          case 0:
            this.$router.push("/login");
            break;
          case 1:
            this.urlHotData = data.data;
            // console.log(JSON.stringify(retData));
            // this.$message.success(data.message);
            break;
          default:
            retData = null;
            // this.$message.error(data.message);
            break;
        }
      })
      .catch((error) => {
        // console.log(error);
        // this.$message.error(error);
      });

    // 网址收藏数据
    this.$axios
      .post("/api/v20/home/url/" + this.username + "/" + this.token, {
        limit: 30,
      })
      .then((response) => {
        let data = response.data;
        // console.log(response)
        switch (data.code) {
          case 0:
            this.$router.push("/login");
            break;
          case 1:
            this.urlData = data.data;
            // console.log(JSON.stringify(retData));
            // this.$message.success(data.message);
            break;
          default:
            retData = null;
            // this.$message.error(data.message);
            break;
        }
      })
      .catch((error) => {
        // console.log(error);
        // this.$message.error(error);
      });

    // 关键词列表数据
    this.$axios
      .post("/api/v20/home/keywds/" + this.username + "/" + this.token, {
        limit: 200,
      })
      .then((response) => {
        let data = response.data;
        // console.log(response)
        switch (data.code) {
          case 0:
            this.$router.push("/login");
            break;
          case 1:
            this.keywdsData = data.data;
            break;
          default:
            retData = null;

            break;
        }
      })
      .catch((error) => {
        // console.log(error);
      });

    //网址搜索
  },
};
</script>