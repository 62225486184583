import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
// 引入样式表
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// import ES6 style
import { VueMasonryPlugin } from 'vue-masonry';
import axios from 'axios'

import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize
} from "vee-validate";

import zh_CN from "vee-validate/dist/locale/zh_CN.json";  //调用语言环境文件
import * as rules from "vee-validate/dist/rules";

// Install VeeValidate rules and localization
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

// 设置语言环境的同时加载该语境下的messages
localize("zh_CN", zh_CN);

// Install VeeValidate components globally
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

// Vue.use()   //多出这一行，就有问题


Vue.use(VueMasonryPlugin)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
// Vue.use(IconsPlugin)

Vue.prototype.$axios = axios
// axios.defaults.baseURL = '/api'  //关键代码

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
