<template>
  <div>
    <page-template>
      <template slot="page-content">
         <home-banner></home-banner>
         <home-content></home-content> 
      </template>
    </page-template>
  </div>  
</template>

<script>

// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import PageTemplate from "../components/PageTemplate.vue";
import HomeBanner from "./HomeBanner.vue"
import HomeContent from './HomeContent.vue';

export default {
  name: "Home",
  components: {
    PageTemplate,
    HomeBanner,
    HomeContent,
  },
};
</script>
