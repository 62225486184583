<template>
  <div id="admin-note">
    <admin-template>
      <template slot="admin-content">
        <admin-navbar
          title="提醒信息列表"
          :linksArray="linksArray"
          :returnUrl="returnUrl"
        ></admin-navbar>
        <!-- 列表 -->
        <b-container>
          <b-table striped hover :items="notesArray" :fields="fields">
            <template v-slot:head(action)>
              <div class="">
                <b-link href="/note/add" class="mr-5"
                  ><b-icon
                    icon="plus"
                    class="border border-info rounded"
                    variant="info"
                  ></b-icon
                ></b-link>

                <b-link href="/note/history"
                  ><b-icon
                    icon="table"
                    class="border border-info rounded"
                    variant="info"
                  ></b-icon
                ></b-link>

              </div>
            </template>

            <template v-slot:cell(is_mark)="data">
              {{ data.item.is_mark == '1' ? "V" : "X" }}
            </template>

            <template v-slot:cell(action)="data">
              <b-link @click="toStop(data.item.id)" class="mr-3"
                ><b-icon icon="volume-mute-fill"></b-icon
              ></b-link>
              <b-link @click="toEdit(data.item)" class="mr-3"
                ><b-icon icon="pen"></b-icon
              ></b-link>
              <b-link @click="toRemove(data.item.id)"
                ><b-icon icon="trash"></b-icon
              ></b-link>
            </template>
          </b-table>
        </b-container>
      </template>
    </admin-template>
  </div>
</template>

<script>
import AdminTemplate from "../components/AdminTemplate";
import AdminNavbar from "../components/AdminNavbar";

export default {
  name: "Note",
  data() {
    return {
      title: "提醒信息列表",
      linksArray: [],
      returnUrl: "/",
      fields: [
        {
          key: "date_note",
          label: "日期",
        },
        {
          key: "note",
          label: "提醒内容",
          class: "text-left",
        },
        {
          key: "is_mark",
          label: "重点提醒",
        },
        {
          key: "action",
          label: "维护操作",
        },
      ],
      notesArray: [],
    };
  },
  components: {
    AdminTemplate,
    AdminNavbar,
  },
  created() {
    this.username = window.sessionStorage.getItem("username");
    this.token = window.sessionStorage.getItem("token");

    this.getData();
  },
  methods: {
    getData() {
      this.$axios
        .post("/api/v20/note/" + this.username + "/" + this.token, {
          isLive: 1,
        })
        .then((response) => {
          // console.log(response);
          let data = response.data;

          switch (data.code) {
            case 0:
              this.$router.push("/login");
              break;
            case 1:
              this.notesArray = data.data;

              // console.log(notesArray)
              break;
            default:
              // this.$message.error(data.message);
              this.break;
          }
        })
        .catch((error) => {
          // console.log(error);
          // this.$message.error(data.message);
        });
    },
    toStop(id) {
      //连接服务器端，状态设置关闭
      this.$axios
        .post("/api/v20/note/live/" + this.username + "/" + this.token, {
          id: id,
          isLive: 0,
        })
        .then((response) => {
          let data = response.data;

          switch (data.code) {
            case 0:
              this.$router.push("/login");
              break;

            case 1:
              this.$bvModal.msgBoxOk(data.message);
              //更新数据
              this.getData();
              this.$router.go(0);
              break;

            default:
              this.$bvModal.msgBoxOk(data.message);
              break;
          }
        })
        .catch((error) => {
          // console.log(error);
          // this.$message.error(error);
        });
    },
    toEdit(row) {
      // console.log(e)
      this.$router.push(
        "/note/edit?row=" + encodeURIComponent(JSON.stringify(row))
      );
    },
    toRemove(id) {},
  },
};
</script>