var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"admin-note"}},[_c('admin-template',[_c('template',{slot:"admin-content"},[_c('admin-navbar',{attrs:{"title":_vm.title,"linksArray":_vm.linksArray,"returnUrl":_vm.returnUrl}}),_c('b-container',{attrs:{"fluid":"sm"}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('validation-provider',{attrs:{"name":"提醒内容","rules":{ required: true, min: 2 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"md":"3"}},[_c('label',[_vm._v("提醒内容")])]),_c('b-col',{attrs:{"md":"9"}},[_c('b-input',{staticClass:"mb-2 mr-sm-2 mb-sm-0",attrs:{"id":"inline-form-input-name","placeholder":"输入提醒内容"},model:{value:(_vm.note),callback:function ($$v) {_vm.note=$$v},expression:"note"}})],1),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"提醒日期","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"md":"3"}},[_vm._v("提醒日期")]),_c('b-col',{attrs:{"md":"9"}},[_c('b-form-datepicker',{staticClass:"mb-2",attrs:{"id":"example-datepicker"},model:{value:(_vm.dateNote),callback:function ($$v) {_vm.dateNote=$$v},expression:"dateNote"}})],1),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"重点提醒","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"md":"3"}},[_vm._v("重点提醒")]),_c('b-col',{staticClass:"text-left",attrs:{"md":"9"}},[_c('b-form-radio-group',{attrs:{"id":"radio-group-2","name":"radio-sub-component"},model:{value:(_vm.isMark),callback:function ($$v) {_vm.isMark=$$v},expression:"isMark"}},[_c('b-form-radio',{attrs:{"value":"1"}},[_vm._v("是")]),_c('b-form-radio',{attrs:{"value":"0"}},[_vm._v("否")])],1)],1),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v("添加提醒内容")])],1)]}}])})],1)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }