<template>
  <div id="page-header">
    <b-navbar toggleable="lg" type="dark" variant="dark">
      <!-- Logo image -->
      <b-navbar-brand href="/">
        <b-img :src="logoUrl" class="mb-1"></b-img>
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <!-- navgation list  -->
        <b-navbar-nav>
          
          <b-nav-item-dropdown text="常用站点" right>
            <b-dropdown-item
              v-for="(item, index) in hotSiteArray"
              :key="index"
              :href="item.url"
              target="_blank"
              >{{ item.title }}</b-dropdown-item
            >
          </b-nav-item-dropdown>

          <b-nav-item-dropdown text="优化工具" right>
            <b-dropdown-item
              v-for="(item, index) in seoArray"
              :key="index"
              :href="item.url"
              target="_blank"
              >{{ item.title }}</b-dropdown-item
            >
          </b-nav-item-dropdown>

          <b-nav-item-dropdown text="公司站群" right>
            <b-dropdown-item
              v-for="(item, index) in workArray"
              :key="index"
              :href="item.url"
              target="_blank"
              >{{ item.title }}</b-dropdown-item
            >
          </b-nav-item-dropdown>

          <b-nav-item-dropdown text="建站平台" right>
            <b-dropdown-item
              v-for="(item, index) in siteArray"
              :key="index"
              :href="item.url"
              target="_blank"
              >{{ item.title }}</b-dropdown-item
            >
          </b-nav-item-dropdown>

          <b-nav-item-dropdown text="系统维护" right>
            <b-dropdown-item
              v-for="(item, index) in itemArray"
              :key="index"
              :href="item.url"
              >{{ item.title }}</b-dropdown-item
            >
          </b-nav-item-dropdown>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-form class="mr-5">
            <b-input-group size="" class="">
              <b-form-input
                type="search"
                v-model="keywds"
                placeholder="百度一下"
                style="width: 300px"
              ></b-form-input>
              <b-input-group-prepend is-text>
                <b-icon icon="search" @click="toBaidu"></b-icon>
              </b-input-group-prepend>
            </b-input-group>
          </b-nav-form>

          <b-nav-item-dropdown right>
            <!-- Using 'button-content' slot -->
            <template v-slot:button-content>
              <em><b-icon icon="person-fill"></b-icon></em>
            </template>
            <b-dropdown-item href="/mine">我的</b-dropdown-item>
            <b-dropdown-item @click="toExit">退出</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import logoUrl from "../assets/logo/logo.png";

export default {
  name: "PageHeader",
  components: {},
  data() {
    return {
      username: null,
      token: null,
      logoUrl: logoUrl,
      keywds: "",
      hotSiteArray: [
        {
          title: "谷歌翻译",
          url: "https://translate.google.cn/",
        },
        {
          title: "百度地图",
          url: "https://map.baidu.com",
        },
        {
          title: "凤凰网",
          url: "http://www.ifeng.com/",
        },
        {
          title: "新网渠道",
          url: "http://agent.xinnet.com/",
        },
        {
          title: "微微云",
          url: "http://bgp.hk",
        },
        {
          title: "办公系统",
          url: "http://oa.jukebao.com/index.php/login",
        },
      ],
      seoArray: [
        {
          title: "百度站长工具",
          url: "https://ziyuan.baidu.com/site/index",
        },
        {
          title: "百度网站统计",
          url: "https://tongji.baidu.com",
        },
        {
          title: "百度指数",
          url: "https://index.baidu.com/v2",
        },
        {
          title: "百度舆情",
          url: "http://yuqing.baidu.com",
        },
      ],
      workArray: [
        {
          title: "公司官网",
          url: "https://www.jukebao.com/",
        },
        {
          title: "技术交流站",
          url: "https://www.jukebao.net/",
        },
        {
          title: "天拓优化站",
          url: "https://www.tiantuo.net/",
        },
        {
          title: "陶瓷优化站",
          url: "https://www.juanbanji.com/",
        },
      ],
      siteArray: [
        {
          title: "系统平台",
          url: "https://root.jukebao.com/",
        },
        {
          title: "模板平台",
          url: "https://theme.jukebao.com/",
        },
      ],
      itemArray: [
        {
          title: "网址分类",
          url: "/item",
        },
        {
          title: "收藏管理",
          url: "/url/current",
        },
        {
          title: "关键词表",
          url: "/url/keywds",
        },
        {
          title: "提醒管理",
          url: "/note",
        },
        {
          title: "签名管理",
          url: "/signature",
        },
      ],
    };
  },
  created() {
    var that = this;
    document.onkeydown = function (e) {
      var key = window.event.keyCode;
      if (key == 13) {
        that.toBaidu();
      }
    };
  },
  methods: {
    toBaidu() {
      window.open(
        "https://www.baidu.com/s?ie=utf-8&wd=" + this.keywds,
        "_blank"
      );
    },
    toExit() {
      this.username = window.sessionStorage.getItem("username");
      this.token = window.sessionStorage.getItem("token");

      //服务器端退出登录
      this.$axios
        .get("/api/v20/user/logout/" + this.username + "/" + this.token)
        .then((response) => {
          // console.log(response);
          let data = response.data;

          switch (data.code) {
            case 0:
              this.$router.push("/login");
              break;
            case 1:
              //清除localStorage值
              localStorage.removeItem("userInfo");
              //清除sessionStorage值
              sessionStorage.removeItem("nickname");
              sessionStorage.removeItem("username");
              sessionStorage.removeItem("token");
              //返回登录页面
              this.$router.push("/login");
              break;

            default:
              this.$bvModal.msgBoxOk(data.message);
              break;
          }
        })
        .catch((error) => {
          // console.log(error);
          this.$bvModal.msgBoxOk(data.message);
        });
    },
  },
};
</script>