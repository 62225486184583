<template>
  <div id="admin-signature">
    <admin-template>
      <template slot="admin-content">
        <admin-navbar
          title="签名列表"
          :linksArray="linksArray"
          :returnUrl="returnUrl"
        ></admin-navbar>
        <!-- 列表 -->
        <b-container>
          <b-table striped hover :items="signatureArray" :fields="fields">
            <template v-slot:head(action)>
              <div class="">
                <b-link href="/signature/add"
                  ><b-icon
                    icon="plus"
                    class="border border-info rounded"
                    variant="info"
                  ></b-icon
                ></b-link>
              </div>
            </template>

            <template v-slot:cell(slot)="data">
              {{ data.index + 1 }}
            </template>

            <template v-slot:cell(action)="data">
              <b-link @click="toEdit(data.item)" class="mr-3"
                ><b-icon icon="pen"></b-icon
              ></b-link>

              <b-link @click="toRemove(data.item.id)"><b-icon icon="trash"></b-icon></b-link>
            </template>
          </b-table>
        </b-container>
      </template>
    </admin-template>
  </div>
</template>

<script>
import AdminTemplate from "../components/AdminTemplate";
import AdminNavbar from "../components/AdminNavbar";

export default {
  name: "Signature",
  data() {
    return {
      title: "签名列表",
      linksArray: [],
      returnUrl: "/",
      fields: [
        {
          key: "slot",
          label: "序号",
        },
        {
          key: "signature",
          label: "签名",
          class: "text-left",
        },
        {
          key: "action",
          label: "维护操作",
        },
      ],
      signatureArray: [],
    };
  },
  components: {
    AdminTemplate,
    AdminNavbar,
  },
  created() {
    this.username = window.sessionStorage.getItem("username");
    this.token = window.sessionStorage.getItem("token");

    this.getData();
  },
  methods: {
    getData() {
      this.$axios
        .get("/api/v20/signature/" + this.username + "/" + this.token)
        .then((response) => {
          // console.log(response);
          let data = response.data;

          if (data.code === 1) {
            this.signatureArray = data.data;
          } else {
            this.$bvModal.msgBoxOk(data.message);
          }
        })
        .catch((error) => {
          // console.log('go-error');
          // this.$message.error(data.message);
        });
    },
    toEdit(row) {
      // console.log(e)
      this.$router.push(
        "/signature/edit?row=" + encodeURIComponent(JSON.stringify(row))
      );
    },
    toRemove(id){

      //连接服务器端，删除数据
      this.$axios
        .post("/api/v20/signature/remove/" + this.username + "/" + this.token, {
          id: id,
        })
        .then((response) => {
          let data = response.data;

          switch (data.code) {
            case 0:
              this.$router.push("/login");
              break;

            case 1:
              //更新数据
              this.getData();
              this.$router.go(0);
              break;

            default:
              // this.$message.error(data.message);
              break;
          }
        })
        .catch((error) => {
          // console.log(error);
          // this.$message.error(error);
        });
    }
  },
};
</script>