<template>
  <div>
    <b-modal
      id="modal-message"
      title=""
      hide-header
      hide-footer
    >
      <b-alert :variant="alert" show>{{ message }}</b-alert>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "Message",
  props: {
    message: String,
    alert: String,
  },
};
</script>