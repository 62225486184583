<template>
  <div id="admin-url">
    <admin-template>
      <template slot="admin-content">
        <admin-navbar
          title="网址收藏列表"
          :linksArray="linksArray"
          :returnUrl="returnUrl"
        ></admin-navbar>
        <!-- 列表 -->
        <b-container>
          <b-table striped hover :items="urlsArray" :fields="fields">
            <template v-slot:head(action)>
              <div class="">
                <b-link @click="handleAdd"
                  ><b-icon
                    icon="plus"
                    class="border border-info rounded"
                    variant="info"
                  ></b-icon
                ></b-link>
              </div>
            </template>

            <template v-slot:cell(slot)="data">
              {{ data.index + 1 }}
            </template>

            <template v-slot:cell(title)="data">

              <b-link
                :href="data.item.url"
                target="_blank"
                class="text-truncate mr-2"
                >{{ data.item.title }}</b-link
              >

              <b-icon class="text-warning" v-if="data.item.is_hot == 1" icon="heart-fill"></b-icon>
              
            </template>

            <template v-slot:cell(action)="data">
              <b-link @click="handleEdit(data.item)" class="mr-3"
                ><b-icon icon="pen"></b-icon
              ></b-link>
              <b-link @click="handleDelete(data.item.id)"
                ><b-icon icon="trash"></b-icon
              ></b-link>
            </template>
          </b-table>
        </b-container>
      </template>
    </admin-template>
  </div>
</template>

<script>
import AdminTemplate from "../components/AdminTemplate";
import AdminNavbar from "../components/AdminNavbar";

export default {
  name: "UrlKeywdsLists",
  data() {
    return {
      title: "关键词网址列表",
      linksArray: [
        {
          title: "网址关键词列表",
          url: "/url/keywds",
        },
      ],
      returnUrl: "/url/keywds",
      username: null,
      token: null,
      keywds: null,
      fields: [
        {
          key: "slot",
          label: "序号",
        },
        {
          key: "title",
          label: "收藏网页标题",
          class: "text-left",
        },
        {
          key: "action",
          label: "维护操作",
        },
      ],
      urlsArray: [],
     
    };
  },
  components: {
    AdminTemplate,
    AdminNavbar,
  },
  methods: {
    handleAdd: function () {
      this.$router.push({
        path: "/url/add",
        query: {
          keywds: this.keywds,
        },
      });
    },
    handleEdit: function (row) {
      this.$router.push(
        "/url/edit?row=" + encodeURIComponent(JSON.stringify(row))
      );
    },
    handleDelete: function (id) {
      let that = this;

      this.$bvModal.msgBoxOk("Data was submitted successfully");

      this.$axios
        .post("/api/v20/url/remove/" + this.username + "/" + this.token, {
          id: id,
        })
        .then((response) => {
      
          let data = response.data;

          switch (data.code) {
            case 0:
              that.$router.push("/login");
              break;

            case 1:
              // 弹窗信息
              that.$bvModal.msgBoxOk("删除成功");
              // 刷新页面
              that.$router.go(0);
              break;

            default:
              that.$bvModal.msgBoxOk(data.message);
              break;
          }
        })
        .catch((error) => {
          // console.log("error");
          // this.$message.error(error);
        });
    },
  },
  created() {
    this.username = window.sessionStorage.getItem("username");
    this.token = window.sessionStorage.getItem("token");
    this.keywds = this.$route.query.keywds;

    this.$axios
      .post("/api/v20/keywds/item/" + this.username + "/" + this.token, {
        keywds: this.keywds,
      })
      .then((response) => {
        // console.log(response);
        let data = response.data;

        switch (data.code) {
          case 0:
            break;
          case 1:
            this.urlsArray = data.data;
            // this.$message.error(data.message);
            break;
          default:
            this.$message.error(data.message);
            break;
        }
      })
      .catch((error) => {
        // console.log("error");
        // this.$message.error(error);
      });
  },
};
</script>