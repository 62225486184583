<template>
  <div id="admin-url">
    <admin-template>
      <template slot="admin-content">
        <admin-navbar
          :title="title"
          :linksArray="linksArray"
          :returnUrl="returnUrl"
        ></admin-navbar>
        <b-container fluid="sm">
          <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.prevent="handleSubmit(onSubmit)">
              <validation-provider
                name="标题"
                :rules="{ required: true, min: 2 }"
                v-slot="{ errors }"
              >
                <b-row class="mb-2">
                  <b-col md="3">
                    <label>标题</label>
                  </b-col>
                  <b-col md="9">
                    <b-input
                      v-model="title"
                      id="inline-form-input-name"
                      class="mb-2 mr-sm-2 mb-sm-0"
                      placeholder="输入标题"
                    ></b-input>
                  </b-col>
                </b-row>
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>

              <validation-provider
                name="网址"
                :rules="{ required: true }"
                v-slot="{ errors }"
              >
                <b-row class="mb-2">
                  <b-col md="3">网址</b-col>
                  <b-col md="9">
                    <b-input
                      v-model="url"
                      id="inline-form-input-name"
                      class="mb-2 mr-sm-2 mb-sm-0"
                      placeholder="输入标题"
                    ></b-input>
                  </b-col>
                </b-row>
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>

              <validation-provider
                name="关键词"
                :rules="{ required: true, min: 2 }"
                v-slot="{ errors }"
              >
                <b-row class="mb-3">
                  <b-col md="3">关键词</b-col>
                  <b-col md="9">
                    <b-input
                      v-model="keywds"
                      id="inline-form-input-name"
                      class="mb-2 mr-sm-2 mb-sm-0"
                      placeholder="输入关键词"
                    ></b-input>
                  </b-col>
                </b-row>
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>

              <validation-provider
                name="推荐"
                :rules="{ required: true }"
                v-slot="{ errors }"
              >
                <b-row class="mb-2">
                  <b-col md="3">推荐</b-col>
                  <b-col md="9" class="text-left">
                    <b-form-radio-group
                      id="radio-group-2"
                      v-model="ishot"
                      name="radio-sub-component"
                    >
                      <b-form-radio value="1">是</b-form-radio>
                      <b-form-radio value="0">否</b-form-radio>
                    </b-form-radio-group>
                  </b-col>
                </b-row>
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>

              <b-button type="submit" variant="primary">编辑网址信息</b-button>
            </b-form>
          </validation-observer>
        </b-container>
      </template>
    </admin-template>
  </div>
</template>

<script>
import AdminTemplate from "../components/AdminTemplate";
import AdminNavbar from "../components/AdminNavbar";

export default {
  name: "UrlAdd",
  data() {
    return {
    
      title: "网址修改",
      linksArray: [
        {
          title: "网址收藏",
          url: "/url/current",
        },
      ],
      returnUrl: "/url/current",
      username: "",
      token: "",
      id: '',
      title: "",
      url: "",
      keywds: "",
      ishot: "0",
    };
  },
  components: {
    AdminTemplate,
    AdminNavbar,
  },
  created() {
    
    this.username = window.sessionStorage.getItem("username");
    this.token = window.sessionStorage.getItem("token");

    let row = JSON.parse(this.$route.query.row);
   
    this.id = row.id
    this.title = row.title;
    this.url = row.url;
    this.keywds = row.keywds;
    this.ishot = row.is_hot;
   
  },
  methods: {
    onSubmit() {
      
      this.$axios
        .post("/api/v20/url/edit/save/" + this.username + "/" + this.token, {
          id: this.id,
          title: this.title,
          url: this.url,
          keywds: this.keywds,
          isHot: this.ishot,
        })
        .then((response) => {
          let data = response.data;
     
          if (data.code === 1) {
            this.$bvModal.msgBoxOk(data.message);
          } else {
            this.$bvModal.msgBoxOk(data.message);
          }
        })
        .catch((error) => {
          // console.log('error')
          // this.$message.error(error);
        });
    },
    onReset() {},
  },
};
</script>