<template>
  <div id="admin-site">
    <admin-template>
      <template slot="admin-content">
        <admin-navbar
          title="网站类型列表"
          :linksArray="linksArray"
          :returnUrl="returnUrl"
        ></admin-navbar>
        <!-- 列表 -->
        <b-container>
          <b-table striped hover :items="urlArray" :fields="fields">
            <template v-slot:head(action)>
              <div class="">
                <b-link @click="handleAdd()"
                  ><b-icon
                    icon="plus"
                    class="border border-info rounded"
                    variant="info"
                  ></b-icon
                ></b-link>
              </div>
            </template>

            <template v-slot:cell(slot)="data">
              {{ data.index + 1 }}
            </template>

            <template v-slot:cell(site)="data">
              <b-link :href="data.item.url" target="_blank">{{
                data.item.site
              }}</b-link>
            </template>

            <template v-slot:cell(action)="data">
              <b-link @click="handleEdit(data.item)" class="mr-3"
                ><b-icon icon="pen"></b-icon
              ></b-link>
              <b-link @click="handleDelete(data.item.id)"><b-icon icon="trash"></b-icon></b-link>
            </template>
          </b-table>
        </b-container>
      </template>
    </admin-template>
  </div>
</template>

<script>
import AdminTemplate from "../components/AdminTemplate";
import AdminNavbar from "../components/AdminNavbar";

export default {
  name: "Site",
  data() {
    return {
      title: "导航网站列表",
      linksArray: [],
      returnUrl: "/",
      fields: [
        {
          key: "slot",
          label: "序号",
        },
        {
          key: "site",
          label: "网站",
          class: "text-left",
        },
        {
          key: "action",
          label: "维护操作",
        },
      ],
      itemid: null,
      item: null,
      urlArray: [],
    };
  },
  components: {
    AdminTemplate,
    AdminNavbar,
  },
  created: function () {
    this.username = window.sessionStorage.getItem("username");
    this.token = window.sessionStorage.getItem("token");

    this.itemid = this.$route.params.itemid;
    this.item = this.$route.params.item;
    this.getData(this.itemid);
  },
  methods: {
    handleAdd: function () {
      this.$router.push("/site/add?itemid=" + this.itemid + '&item=' + this.item);
    },
    handleEdit: function (row) {
      // console.log(row.id)
      // this.$router.push('/site/edit/'+ row.itemid + '/' + row.id)
      this.$router.push({
        path: "/site/edit",
        query: {id: row.id, itemid: row.itemid, item: this.item, site: row.site, url: row.url, isHot: row.is_hot },
      });
    },
    handleDelete: function (id) {
      this.$axios
        .post("/api/v20/site/remove/" + this.username + "/" + this.token, {
          id: id,
        })
        .then((response) => {
          // console.log(response);
          let data = response.data;

          switch (data.code) {
            case 0:
              this.$router.push("/login");
              break;
            case 1:
              this.getData(this.itemid);
              this.$bvModal.msgBoxOk('网站删除成功')
              break;
            default:
              this.$bvModal.msgBoxOk(data.message)
              break;
          }
        })
        .catch((error) => {
          // console.log(error);
          // this.$message.error(data.message);
        });
    },
    getData: function (itemid) {
      this.$axios
        .post("/api/v20/site/" + this.username + "/" + this.token, {
          itemid: itemid,
        })
        .then((response) => {
          // console.log(response);
          let data = response.data;

          switch (data.code) {
            case 0:
              this.$router.push("/login");
              break;
            case 1:
              this.urlArray = data.data;
              break;
            default:
              // this.$message.error(data.message);
              break;
          }
        })
        .catch((error) => {
          // console.log(error);
          // this.$message.error(data.message);
        });
    },
  },
};
</script>