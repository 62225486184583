<template>
  <div id="admin-url">
    <admin-template>
      <template slot="admin-content">
        <admin-navbar
          title="网址收藏搜索列表"
          :linksArray="linksArray"
          :returnUrl="returnUrl"
        ></admin-navbar>
        <!-- 列表 -->
        <b-container>
          <b-table striped hover :items="urlsArray" :fields="fields">
            <template v-slot:cell(slot)="data">
              {{ data.index + 1 }}
            </template>

            <template v-slot:cell(title)="data">
              <b-link @click="toKeywdsList(data.item.keywds)" class="mr-3"
                ><b-icon icon="folder-symlink" variant="=info"></b-icon
              ></b-link>
              <b-link :href="data.item.url" target="_blank">{{
                data.item.title
              }}</b-link>
              <b-icon
                class="text-warning ml-2"
                v-if="data.item.is_hot == 1"
                icon="heart-fill"
              ></b-icon>
            </template>

            <template v-slot:cell(action)="data">
              <b-link @click="handleEdit(data.item)" class="mr-3"
                ><b-icon icon="pen"></b-icon
              ></b-link>
              <b-link @click="handleDelete(data.item.id)"
                ><b-icon icon="trash"></b-icon
              ></b-link>
            </template>
          </b-table>
        </b-container>
      </template>
    </admin-template>
  </div>
</template>

<script>
import AdminTemplate from "../components/AdminTemplate";
import AdminNavbar from "../components/AdminNavbar";

export default {
  name: "UrlSearch",
  data() {
    return {
      title: "网址收藏搜索列表",
      linksArray: [],
      returnUrl: "/",
      username: "",
      token: "",
      searchKeywds: null,
      fields: [
        {
          key: "slot",
          label: "序号",
        },
        {
          key: "title",
          label: "收藏网页标题",
          class: "text-left",
        },
        {
          key: "action",
          label: "维护操作",
        },
      ],
      urlsArray: [],
    };
  },
  components: {
    AdminTemplate,
    AdminNavbar,
  },
  methods: {
    toKeywdsList: function (keywds) {
      this.$router.push({
        path: "/url/keywds/lists",
        query: {
          keywds: keywds,
        },
      });
    },

    handleEdit: function (row) {
      this.$router.push(
        "/url/edit?row=" + encodeURIComponent(JSON.stringify(row))
      );
    },
    handleDelete: function (id) {
      let that = this;

      this.$bvModal.msgBoxOk("Data was submitted successfully");

      this.$axios
        .post("/api/v20/url/remove/" + this.username + "/" + this.token, {
          id: id,
        })
        .then((response) => {
          let data = response.data;

          switch (data.code) {
            case 0:
              that.$router.push("/login");
              break;

            case 1:
              // 弹窗信息
              that.$bvModal.msgBoxOk("删除成功");
              // 刷新页面
              that.$router.go(0);
              break;

            default:
              that.$bvModal.msgBoxOk(data.message);
              break;
          }
        })
        .catch((error) => {
          // console.log("error");
          // this.$message.error(error);
        });
    },
  },
  created: function () {
    let that = this;
    this.username = window.sessionStorage.getItem("username");
    this.token = window.sessionStorage.getItem("token");
    this.searchKeywds = this.$route.query.keywds;
    // console.log(keywds)

    this.$axios
      .post("/api/v20/url/search/" + this.username + "/" + this.token, {
        keywds: this.searchKeywds,
      })
      .then((response) => {
        
        let data = response.data;

        switch (data.code) {
          case 0:
            window.sessionStorage.setItem("token", null);
            that.$router.push("/login");
            break;
          case 1:
            let urlsArray = data.data;
            this.urlsArray = urlsArray.slice(0, 20);

            // this.$message.error(data.message);
            break;
          case 2:
            this.urlsArray = [];
            break;
          default:
            // that.$bvModel.msgBoxOk(data.message);
            break;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>