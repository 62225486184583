<template>
  <div id="admin-item">
    <admin-template>
      <template slot="admin-content">
        <admin-navbar
          :title="title"
          :linksArray="linksArray"
          :returnUrl="returnUrl"
        ></admin-navbar>

        <b-container>
          <b-row align-h="center">
            <b-col></b-col>
            <b-col md="5">
              <validation-observer ref="observer" v-slot="{ handleSubmit }">
                <b-form @submit.prevent="handleSubmit(onSubmit)">
                  <validation-provider
                    name="分类"
                    :rules="{ required: true, min: 4 }"
                    v-slot="{ errors }"
                  >
                    <label class="sr-only" for="inline-form-input-item"
                      >分类名称</label
                    >
                    <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                      <b-input
                        v-model="item"
                        id="inline-form-input-item"
                        placeholder="输入分类名称"
                        autofocus
                      ></b-input>
                    </b-input-group>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </validation-provider>

                  <validation-provider
                    name="编号"
                    :rules="{ required: true, numeric: true }"
                    v-slot="{ errors }"
                  >
                    <label class="sr-only" for="inline-form-input-slot"
                      >分类编号</label
                    >
                    <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                      <b-input
                        v-model="slot"
                        id="inline-form-input-slot"
                        placeholder="输入分类编号"
                        autofocus
                      ></b-input>
                    </b-input-group>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </validation-provider>

                  <b-button
                    type="submit"
                    variant="primary"
                    class="my-3"
                    style="width: 100%"
                    >添加新分类</b-button
                  >
                </b-form>
              </validation-observer>
            </b-col>
            <b-col></b-col>
          </b-row>
        </b-container>
      </template>
    </admin-template>
  </div>
</template>

<script>
import AdminTemplate from "../components/AdminTemplate";
import AdminNavbar from "../components/AdminNavbar";

export default {
  name: "ItemEdit",
  data() {
    return {
      show: true,
      title: "网站分类修改",
      linksArray: [
        {
          title: "网站类型",
          url: "/item",
        },
      ],
      returnUrl: "/item",
      itemid: "",
      item: "",
      slot: 0,
    };
  },
  components: {
    AdminTemplate,
    AdminNavbar,
  },
  created() {
    let itemRow = JSON.parse(this.$route.query.item);
    // console.log(itemRow);
    this.item = itemRow.item;
    this.slot = itemRow.slot;
    this.itemid = itemRow.itemid;
  },
  methods: {
    onSubmit() {
      let username = window.sessionStorage.getItem("username");
      let token = window.sessionStorage.getItem("token");

      this.$axios
        .post("/api/v20/site/item/edit/" + username + "/" + token, {
          item: this.item,
          itemid: this.itemid,
          slot: this.slot,
        })
        .then((response) => {
         
          let data = response.data;

          switch (data.code) {
            case 0:
              window.sessionStorage.setItem("token", null);
              this.$router.push("/login");
              break;
            case 1:
              this.$router.push("/item");
              break;
            default:
              this.$bvModel.msgBoxOk(data.message);
              break;
          }
        })
        .catch((error) => {
          // console.log(error);
          this.$bvModel.msgBoxOk(data.message);
        });
    },
    onReset() {},
  },
};
</script>