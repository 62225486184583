<template>
  <div id="admin-url">
    <admin-template>
      <template slot="admin-content">
        <admin-navbar
          title="网站类型列表"
          :linksArray="linksArray"
          :returnUrl="returnUrl"
        ></admin-navbar>
        <!-- 列表 -->
        <b-container>
          <b-table striped hover :items="urlArray" :fields="fields">
            <template v-slot:cell(action)="data">
              <b-link @click="toEdit(data.item.slot)"  class="mr-3"><b-icon icon="pen"></b-icon></b-link>
              <b-link ><b-icon icon="trash"></b-icon></b-link>
              
            </template>
          </b-table>

          <div class="text-left my-5">
            <b-link href="/url/add"
              ><b-icon
                icon="plus"
                class="border border-info rounded"
                variant="info"
              ></b-icon
            ></b-link>
          </div>
        </b-container>
      </template>
    </admin-template>
  </div>
</template>

<script>
import AdminTemplate from "../components/AdminTemplate";
import AdminNavbar from "../components/AdminNavbar";

export default {
  name: "Url",
  data() {
    return {
      title: "网站类型列表",
      linksArray: [
       
      ],
      returnUrl: "/",
      fields: [
        {
          key: "slot",
          label: "序号",
        },
        {
          key: "url",
          label: "收藏网址",
        },
        {
          key: "action",
          label: "维护操作",
        },
      ],
      urlArray: [
        {
          slot: 1,
          title: "聚客宝网站欢迎您",
          url: "http://www.jukebao.com",
          keywds: '聚客宝',
          isHot: false,
        },
        {
          slot: 2,
          title: "天拓网站欢迎您",
          url: "http://www.tiantuo.net",
          keywds: '天拓',
          isHot: false,
        },
      ],
     
    };
  },
  components: {
    AdminTemplate,
    AdminNavbar,
  },
  methods: {
    toEdit(id){
      // console.log(e)
      this.$router.push({path: '/url/edit/'+ id})
    }
  }
};
</script>