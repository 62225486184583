
<template>
  <div class="container login-box">
    <b-card no-body class="overflow-hidden">
      <b-row no-gutters>
        <b-col md="6">
          <b-card-img :src="loginLogo" class="rounded-0"></b-card-img>
        </b-col>
        <b-col md="6">
          <b-card-body title="聚客宝网站导航用户登录" class="mt-4 px-5">
            <!-- login form part -->
            <validation-observer ref="observer" v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(onSubmit)">
                <validation-provider
                  name="账号"
                  :rules="{ required: true, alpha_num: true, min: 5 }"
                  v-slot="{ errors }"
                >
                  <div class="form-group row mt-4 mb-3">
                    <label for="inputUsername" class="col-sm-2 col-form-label"
                      >账号</label
                    >
                    <div class="col-sm-10">
                      <input
                        v-model="form.username"
                        :state="getValidationState(errors)"
                        type="text"
                        class="form-control"
                        id="inputUsername"
                        placeholder="请输入用户账号"
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </div>
                  </div>
                </validation-provider>

                <validation-provider
                  name="Food"
                  :rules="{ required: true, alpha_num: true, min: 5 }"
                  v-slot="{ errors }"
                >
                  <div class="form-group row my-3">
                    <label for="inputPassword" class="col-sm-2 col-form-label"
                      >密码</label
                    >
                    <div class="col-sm-10">
                      <input
                        v-model="form.password"
                        :state="getValidationState(errors)"
                        type="password"
                        class="form-control"
                        id="inputPassword"
                        placeholder="请输入用户密码"
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </div>
                  </div>
                </validation-provider>

                <div class="form-group my-3">
                  <b-row>
                    <b-col md="5" offset-md="2">
                      <b-button
                        block
                        type="submit"
                        variant="success"
                        style="width: 100%"
                        >登录</b-button
                      >
                    </b-col>
                    <b-col md="5">
                      <b-button
                        block
                        type=""
                        @click="onReset"
                        variant="info"
                        style="width: 100%"
                        >重置</b-button
                      >
                    </b-col>
                  </b-row>
                </div>
              </form>
            </validation-observer>
            <!-- login form part -->
          </b-card-body>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<style>
.login-box {
  margin-top: 150px;
}
</style>

<script>
import loginLogo from "../assets/login-logo.jpg";

export default {
  data() {
    return {
      loginLogo: loginLogo,
      foods: [
        { value: null, text: "Choose..." },
        { value: "apple", text: "Apple" },
        { value: "orange", text: "Orange" },
      ],
      form: {
        username: null,
        password: null,
      },
    };
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      // console.log(dirty)
      return dirty || validated ? valid : null;
    },
    onReset() {
      this.form = {
        username: null,
        password: null,
      };

      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
    onSubmit() {
      this.$axios
        .post("/api/v20/user/login", {
          username: this.form.username,
          password: this.form.password,
        })
        .then((response) => {
          let data = response.data;
          // console.log(data)
          // let userInfo = JSON.stringify(data.data.userInfo);
          let userInfo = data.data.userInfo;
          let username = data.data.username;
          let nickname = data.data.nickname;
          let token = data.data.token;
          // this.$message.success(data.message);
          if (data.code == 1) {
            //记忆存储，方便下次自动登录
            localStorage.setItem("userInfo", userInfo);
            localStorage.setItem("username", username);

            //临时存储
            window.sessionStorage.setItem("username", username);
            window.sessionStorage.setItem("nickname", nickname);
            window.sessionStorage.setItem("token", token);
            this.$router.push("/");
          } else {
            this.$router.push("/login");
          }
        })
        .catch((error) => {});
    },
  },
  beforeCreate: function () {
    //如果存在sessionStorage('username')则说明是处理登录状态，不需要再调用localStorage('userInfo')
    // username使用临时存储的目的在于判断断开连接的状态

    const username = sessionStorage.getItem('username')
    const userInfo = localStorage.getItem("userInfo");

    if (!username || userInfo) {
      
      this.$axios
        .post("/api/v20/user/reminder", {
          userInfo: userInfo,
        })
        .then((response) => {
          let data = response.data;

          if (data.code == 1) {
            let userInfo = data.data.userInfo;
            let username = data.data.username;
            let nickname = data.data.nickname;
            let token = data.data.token;
            //记忆存储，方便下次自动登录
            localStorage.setItem("userInfo", userInfo);
  
            //临时存储
            window.sessionStorage.setItem("username", username);
            window.sessionStorage.setItem("nickname", nickname);
            window.sessionStorage.setItem("token", token);
            this.$router.push("/");
          } 
        })
        .catch((error) => {
          console.log("error");
        });
    }
  },
};
</script>