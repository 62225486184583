<template>
  <div id="page-footer">
    <b-card bg-variant="info" text-variant="white" class="text-center" fluid>
      <b-card-text
        >
        Vue-cli4 + BootstrapVue
        </b-card-text
      >
    </b-card>
  </div>
</template>

<script>
export default {
  name: "PageFooter",
  components: {},
};
</script>