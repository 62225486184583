<template>
  <div id="admin-note">
    <admin-template>
      <template slot="admin-content">
        <admin-navbar
          :title="title"
          :linksArray="linksArray"
          :returnUrl="returnUrl"
        ></admin-navbar>
        <b-container fluid="sm">
          <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.prevent="handleSubmit(onSubmit)">
              <validation-provider
                name="提醒内容"
                :rules="{ required: true, min: 2 }"
                v-slot="{ errors }"
              >
                <b-row class="mb-2">
                  <b-col md="3">
                    <label>提醒内容</label>
                  </b-col>
                  <b-col md="9">
                    <b-input
                      v-model="note"
                      id="inline-form-input-name"
                      class="mb-2 mr-sm-2 mb-sm-0"
                      placeholder="输入提醒内容"
                    ></b-input>
                  </b-col>
                  <span class="text-danger">{{ errors[0] }}</span>
                </b-row>
              </validation-provider>

              <validation-provider
                name="提醒日期"
                :rules="{ required: true }"
                v-slot="{ errors }"
              >
                <b-row class="mb-2">
                  <b-col md="3">提醒日期</b-col>
                  <b-col md="9">
                    <b-form-datepicker
                      id="example-datepicker"
                      v-model="dateNote"
                      class="mb-2"
                    ></b-form-datepicker>
                  </b-col>
                  <span class="text-danger">{{ errors[0] }}</span>
                </b-row>
              </validation-provider>

              <validation-provider
                name="重点提醒"
                :rules="{ required: true }"
                v-slot="{ errors }"
              >
                <b-row class="mb-2">
                  <b-col md="3">重点提醒</b-col>
                  <b-col md="9" class="text-left">
                    <b-form-radio-group
                      id="radio-group-2"
                      v-model="isMark"
                      name="radio-sub-component"
                    >
                      <b-form-radio value="1">是</b-form-radio>
                      <b-form-radio value="0">否</b-form-radio>
                    </b-form-radio-group>
                  </b-col>
                  <span class="text-danger">{{ errors[0] }}</span>
                </b-row>
              </validation-provider>
              <b-button type="submit" variant="primary">修改提醒内容</b-button>
            </b-form>
          </validation-observer>
        </b-container>
      </template>
    </admin-template>
  </div>
</template>

<script>
import AdminTemplate from "../components/AdminTemplate";
import AdminNavbar from "../components/AdminNavbar";

export default {
  name: "NoteEdit",
  data() {
    return {
      itemid: "",
      show: true,
      title: "提醒修改",
      linksArray: [
        {
          title: "提醒管理",
          url: "/note",
        },
      ],
      returnUrl: "/note",

      username: null,
      token: null,
      id: null,
      note: "",
      dateNote: "",
      isMark: "0",
    };
  },
  components: {
    AdminTemplate,
    AdminNavbar,
  },
  created() {
    this.username = window.sessionStorage.getItem("username");
    this.token = window.sessionStorage.getItem("token");

    let noteRow = JSON.parse(this.$route.query.row);

    this.id = noteRow.id;
    this.note = noteRow.note;
    this.dateNote = noteRow.date_note;
    this.isMark = noteRow.is_mark;
  },
  methods: {
    onSubmit() {
      this.$axios
        .post("/api/v20/note/edit/save/" + this.username + "/" + this.token, {
          id: this.id,
          note: this.note,
          dateNote: this.dateNote,
          isMark: this.isMark,
        })
        .then((response) => {
          let data = response.data;
          // console.log(response);

          switch (data.code) {
            case 0:
              this.$router.push("/login");
              break;
            
            case 1:
              this.$bvModal.msgBoxOk(data.message);
              break;

            default:
              this.$bvModal.msgBoxOk(data.message);
              break;
          }
        })
        .catch((error) => {
          //  this.$bvModal.msgBoxOk(data.message);
        });
    },
    onReset() {},
  },
};
</script>