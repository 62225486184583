<template>
    <div id="page-template">
        <page-header></page-header>
        <slot name="page-content">
            
        </slot>
        <page-footer></page-footer>
    </div>
</template>

<script>
import PageHeader from "../components/PageHeader";
import PageFooter from "../components/PageFooter";

export default {
    name: 'PageTemplate',
    components: {
        PageHeader,
        PageFooter,
    }
}
</script>