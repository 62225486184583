<template>
  <div id="home-banner">
    <b-jumbotron bg-variant="info" text-variant="white" border-variant="dark" class="mb-1">
      <template v-slot:header>
        <h1 class="display-4 mb-5">{{ signaturePri }}</h1>
      </template>

      <template v-slot:lead>
       <h3>{{ signatureSec }}</h3>
      </template>

      <p></p>
    </b-jumbotron>
  </div>
</template>

<script>
export default {
  name: "HomeBanner",
  data() {
    return {
      username: "",
      token: "",
      signaturePri: null,
      signatureSec: null,
    };
  },
  created: function () {
    this.username = window.sessionStorage.getItem("username");
    this.token = window.sessionStorage.getItem("token");

    this.$axios
      .get("/api/v20/home/signature/" + this.username + "/" + this.token)
      .then((response) => {
        let data = response.data;

        switch (data.code) {
          case 0:
            this.$router.push("/login");
            break;

          case 1:
            let banner = data.data;
            if(banner[0].signature){
              this.signaturePri = banner[0].signature
            }
            if(banner[1].signature){
              this.signatureSec = banner[1].signature
            }
            // this.$message.success(data.message);
            break;

          default:
            // this.$message.error(data.message);
            break;
        }
      })
      .catch((error) => {
        // this.$message.error(error);
      });
  },
};
</script>