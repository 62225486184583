<template>
  <div id="admin-url">
    <admin-template>
      <template slot="admin-content">
        <admin-navbar
          title="网址关键词列表"
          :linksArray="linksArray"
          :returnUrl="returnUrl"
        ></admin-navbar>
        <!-- 列表 -->
        <b-container class="my-5">
          <b-row align-h="start">
            <b-col
              v-for="(item, index) in keywdsArray"
              :key="index"
              md="3"
              class="text-left"
            >
              <b-link @click="handleDel(item.id)">
                <b-icon
                  icon="trash"
                  class="rounded-circle mr-1"
                  variant="secondary"
                ></b-icon>
              </b-link>

              <b-link
                class="ml-3 mr-3"
                @click="toKeywdsList(item.keywds)"
                >{{ item.keywds }}
                <b-badge variant="warning">{{ item.total }}</b-badge></b-link
              >
            </b-col>
          </b-row>
        </b-container>

        

      </template>
    </admin-template>
  </div>
</template>

<script>
import AdminTemplate from "../components/AdminTemplate";
import AdminNavbar from "../components/AdminNavbar";

export default {
  name: "UrlKeywds",
  data() {
    return {
      title: "网址关键词列表",
      linksArray: [],
      returnUrl: "/url/current",
      keywdsArray: [],
      // keywds2Array: [],
      username: null,
      token: null,
    };
  },
  components: {
    AdminTemplate,
    AdminNavbar,
  },
  created() {
    let that = this;
    this.username = window.sessionStorage.getItem("username");
    this.token = window.sessionStorage.getItem("token");

    this.$axios
      .get("/api/v20/keywds/" + this.username + "/" + this.token)
      .then((response) => {
        // console.log(response);
        let data = response.data;

        switch (data.code) {
          case 0:
            this.$router.push("/login");
            break;
          case 1:
            let keywdsArray = data.data;
           
            keywdsArray.sort((a, b) => {
              return a.keywds - b.keywds;
            });
            this.keywdsArray = keywdsArray;
           
            break;
          default:
            break;
        }
      })
      .catch((error) => {
        // console.log("error");
        // this.$message.error(error);
      });
  },
  methods: {
    handleDel(id) {
      // TODO
    },
    toKeywdsList(keywds){
      this.$router.push('/url/keywds/lists?keywds=' + keywds)
    }
  },
};
</script>