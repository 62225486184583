import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Mine from '../views/Mine.vue'
import Login from '../views/Login.vue'
import LoginRegister from '../views/LoginRegister.vue'

import Item from '../views/Item.vue'
import ItemAdd from '../views/ItemAdd.vue'
import ItemEdit from '../views/ItemEdit.vue'

import Site from '../views/Site.vue'
import SiteAdd from '../views/SiteAdd.vue'
import SiteEdit from '../views/SiteEdit.vue'

import Url from '../views/Url.vue'
import UrlHot from '../views/UrlHot.vue'
import UrlSearch from '../views/UrlSearch.vue'
import UrlKeywds from '../views/UrlKeywds.vue'
import UrlKeywdsCount from '../views/UrlKeywdsCount.vue'
import UrlKeywdsLists from '../views/UrlKeywdsLists.vue'
import UrlCurrent from '../views/UrlCurrent.vue'
import UrlAdd from '../views/UrlAdd.vue'
import UrlEdit from '../views/UrlEdit.vue'

import Note from '../views/Note.vue'
import NoteAdd from '../views/NoteAdd.vue'
import NoteEdit from '../views/NoteEdit.vue'
import NoteHistory from '../views/NoteHistory.vue'

import Signature from '../views/Signature.vue'
import SignatureAdd from '../views/SignatureAdd.vue'
import SignatureEdit from '../views/SignatureEdit.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { index: 1, title: '聚客宝导航站' }
  },
  {
    path: '/mine',
    name: 'Mine',
    component: Mine,
    meta: { index: 2, title: '聚客宝导航站' }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { index: 110, title: '聚客宝导航站' }
  },
  {
    path: '/login/register',
    name: 'LoginRegister',
    component: LoginRegister,
    meta: { index: 120, title: '聚客宝导航站' }
  },
  {
    path: '/item',
    name: 'Item',
    component: Item,
    meta: { index: 10, title: '聚客宝导航站' }
  },
  {
    path: '/item/add',
    name: 'ItemAdd',
    component: ItemAdd,
    meta: { index: 11, title: '聚客宝导航站' }
  },
  {
    path: '/item/edit',
    name: 'ItemEdit',
    component: ItemEdit,
    meta: { index: 12, title: '聚客宝导航站' }
  },
  {
    path: '/site/:itemid/:item',
    name: 'Site',
    component: Site,
    meta: { index: 20, title: '聚客宝导航站' }
  },
  {
    path: '/site/add',
    name: 'SiteAdd',
    component: SiteAdd,
    meta: { index: 21, title: '聚客宝导航站' }
  },
  {
    path: '/site/edit',
    name: 'SiteEdit',
    component: SiteEdit,
    meta: { index: 22, title: '聚客宝导航站' }
  },
  {
    path: '/url',
    name: 'Url',
    component: Url,
    meta: { index: 31, title: '聚客宝导航站' }
  },
  {
    path: '/url/hot',
    name: 'UrlHot',
    component: UrlHot,
    meta: { index: 32, title: '聚客宝导航站' }
  },
  {
    path: '/url/search',
    name: 'UrlSearch',
    component: UrlSearch,
    meta: { index: 33, title: '聚客宝导航站' }
  },
  {
    path: '/url/keywds',
    name: 'UrlKeywds',
    component: UrlKeywds,
    meta: { index: 34, title: '聚客宝导航站' }
  },
  {
    path: '/url/keywds/lists',
    name: 'url-keywds-lists',
    component: UrlKeywdsLists,
    meta: { index: 35, title: '聚客宝导航站' }
  },
  {
    path: '/url/keywds/count',
    name: 'url-keywds-count',
    component: UrlKeywdsCount,
    meta: { index: 36, title: '聚客宝导航站' }
  },
  {
    path: '/url/current',
    name: 'UrlCurrent',
    component: UrlCurrent,
    meta: { index: 37, title: '聚客宝导航站' }
  },
  {
    path: '/url/add',
    name: 'UrlAdd',
    component: UrlAdd,
    meta: { index: 38, title: '聚客宝导航站' }
  },
  {
    path: '/url/edit',
    name: 'UrlEdit',
    component: UrlEdit,
    meta: { index: 39, title: '聚客宝导航站' }
  },
  {
    path: '/note',
    name: 'Note',
    component: Note,
    meta: { index: 51, title: '聚客宝导航站' }
  },
  {
    path: '/note/add',
    name: 'NoteAdd',
    component: NoteAdd,
    meta: { index: 52, title: '聚客宝导航站' }
  },
  {
    path: '/note/edit',
    name: 'NoteEdit',
    component: NoteEdit,
    meta: { index: 53, title: '聚客宝导航站' }
  },
  {
    path: '/note/history',
    name: 'NoteHistory',
    component: NoteHistory,
    meta: { index: 54, title: '聚客宝导航站' }
  },
  {
    path: '/signature',
    name: 'Signature',
    component: Signature,
    meta: { index: 61, title: '聚客宝导航站' }
  },
  {
    path: '/signature/add',
    name: 'SignatureAdd',
    component: SignatureAdd,
    meta: { index: 62, title: '聚客宝导航站' }
  },
  {
    path: '/signature/edit',
    name: 'SignatureEdit',
    component: SignatureEdit,
    meta: { index: 63, title: '聚客宝导航站' }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {

  const tokenStr = window.sessionStorage.getItem('token')

  // console.log('tokenstr=', tokenStr)
  // 设置页面标题参数
  if (to.meta.title) {
    document.title = to.meta.title
  }

  if (to.path === '/login' || to.path === '/login/reigster') return next();

  if (tokenStr === null || tokenStr === '') {

    next('/login')

  } else {
    next()
  }

})

export default router
