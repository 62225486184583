<template>
  <div id="admin-navbar">
    <b-row align-v="center" class="border">
      <b-col md="10">
        <!-- 导航链接 -->
        <b-breadcrumb class="bg-light" style="margin: 0">
          <b-breadcrumb-item href="/" class="text-light">
          <b-icon
              icon="house-fill"
              scale="1.25"
              shift-v="1.25"
              aria-hidden="true"
            ></b-icon>
          </b-breadcrumb-item>
            
          <!-- <b-breadcrumb-item href="/" class="text-light">
            
             Home
          </b-breadcrumb-item> -->

          <b-breadcrumb-item v-for="(item, index) in linksArray" :key="index" :href="item.url" class="text-light"
            >{{ item.title }}</b-breadcrumb-item
          >
          <b-breadcrumb-item active>{{ title }}</b-breadcrumb-item>
        </b-breadcrumb>
      </b-col>

      <b-col md="2">
        <!-- 返回按钮 -->
        <b-link :href="returnUrl"><b-icon icon="reply-all" variant="info" style="width: 30px; height: 30px;"></b-icon></b-link>
      </b-col>
    </b-row>
    <h4 class="my-4 text-info">{{ title }}</h4>
  </div>
</template>

<script>
export default {
  name: "AdminNavbar",
  props: {
      title: String,
      linksArray: Array,
      returnUrl: String,
  },
  components: {},
};
</script>